import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { groupsRef } from 'firebase-config';
import { Typography } from '@material-ui/core';

const styles = theme => ({
  root: {
    margin: theme.spacing.unit * 2,
  },
});

class Group extends Component {
  constructor(props) {
    super(props);

    this.state = {
      group: [],
    };
  }

  componentWillMount() {
    groupsRef.doc(this.props.match.params.id).get().then((snap) => {
      this.setState({
        group: snap.data(),
      });
    });
  }

  render() {
    const { classes } = this.props;
    const { group } = this.state;

    return (
      <div className={classes.root}>
        <Typography variant="h5" component="h2">{group.name}</Typography>
        <Typography variant="body1" className={classes.desc}>{group.desc}</Typography>
      </div>
    );
  }
}

export default  withStyles(styles)(Group);