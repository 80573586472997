import React, { Component } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase-config';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { Toolbar, Menu, MenuItem, Avatar } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';

import logo from 'logo.svg';

const styles = theme => ({
  root: {
    width: '100%',
  },
  grow: {
    flexGrow: 1,
  },
  avatar: {
    marginRight: theme.spacing.unit,
  },
  logo: {
    marginRight: theme.spacing.unit,
    maxHeight: 50,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing.unit * 2,
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing.unit * 3,
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  actions: {
    display: 'flex',
  },
});

class Nav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      anchorEl: null,
    };
  }

  handleOpen = (e) => {
    this.setState({
      open: true,
      anchorEl: e.currentTarget,
    });
  }

  handleClose = (e) => {
    this.setState({
      open: false,
      anchorEl: null,
    });
  }

  handleLogout = () => {
    firebase.auth().signOut();
    this.handleClose();
  }

  render() {
    const { classes, user } = this.props;
    const { open, anchorEl } = this.state;

    return (
      <AppBar position="static" color="default">
        <Toolbar>
          <Button component={({...props}) => <Link to='/' {...props} />}>
            <img src={logo} title="RollDnD" alt="RollDnD" className={classes.logo} />
          </Button>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
            />
          </div>
          <div className={classes.grow} />
          <div className={classes.actions}>
            {
              (user) ? (
                <React.Fragment>
                  <Button onClick={this.handleOpen}>
                    <Avatar alt={user.displayName} src={user.photoURL} className={classes.avatar} />
                    {user.displayName}
                  </Button>
                  <Menu 
                    id="profile-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={this.handleClose}
                  >
                    <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
                  </Menu>
                </React.Fragment>
              ) : (
                <Button component={({...props}) => <Link to='/' {...props} />}>
                  Login
                </Button>
              )
            }
          </div>
        </Toolbar>
      </AppBar>
    );
  }
}

Nav.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Nav);
