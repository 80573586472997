import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

export const uiConfig = {
  signInFlow: 'popup',
  signInSuccessUrl: '/',
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
  ]
};

export const config = {
  apiKey: 'AIzaSyDeaMuKnBEQAjKGf6VCDWoKKwRQPZlaJEE',
  authDomain: 'airdnd-f6e36.firebaseapp.com',
  databaseURL: 'https://airdnd-f6e36.firebaseio.com',
  projectId: 'airdnd-f6e36',
  storageBucket: 'airdnd-f6e36.appspot.com',
  messagingSenderId: '803934757072',
};

firebase.initializeApp(config);
export default firebase;

export const db = firebase.firestore();
export const groupsRef = db.collection('groups');