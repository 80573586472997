import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Nav from 'components/Nav';
import firebase from 'firebase-config';
import Home from 'views/Home';
import Group from 'views/Group';
import 'App.css';

class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      user: undefined,
    };
  }
  componentWillMount() {
    firebase.auth().onAuthStateChanged(this.handleAuth);
  }

  handleAuth = (user) => {
    this.setState({ user });
  }

  render() {
    const { user } = this.state;

    return (
      <Router>
        <div className="App">
          <Nav user={user} />
          <Route 
            path="/" 
            exact 
            component={(props) => <Home user={user} {...props} />}
          />
          <Route 
            path="/groups/:id"
            component={(props) => <Group user={user} {...props} />}
          />
        </div>
      </Router>
    );
  }
}

export default App;
