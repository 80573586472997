import React from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Card, CardMedia, CardContent, Typography, CardActions, Button } from '@material-ui/core';

const styles = theme => ({
  root: {},
  card: {
    minHeight: 300,
    height: 300,
    maxHeight: 300,
  },
  media: {
    height: 140,
  },
  content: {
    textAlign: 'left',
  },
  desc: {
    minHeight: 40,
    height: 40,
    maxHeight: 40,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  }
});

const Group = (props) => {
  const { classes, group } = props;

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardMedia className={classes.media} image="https://placehold.it/345x140" />
        <CardContent className={classes.content}>
          <Typography variant="h5" component="h2">{group.name}</Typography>
          <Typography variant="body1" className={classes.desc}>{group.desc}</Typography>
        </CardContent>
        <CardActions>
          <Button
            size="small"
            color="primary"
            component={({...props}) => <Link to={`/groups/${group.id}`} {...props} />}
          >
            Learn More
          </Button>
        </CardActions>
      </Card>
    </div>
  );
};

export default withStyles(styles)(Group);