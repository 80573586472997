import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Group from 'components/Group';
import { groupsRef } from 'firebase-config';

const styles = theme => ({
  root: {
    margin: theme.spacing.unit * 2,
  },
  grid: {
    flexGrow: 1,
  },
});

class Groups extends Component {
  constructor(props) {
    super(props);

    this.state = {
      groups: [],
    };
  }

  componentWillMount() {
    groupsRef.get().then((snap) => {
      snap.forEach((doc) => {
        this.setState({
          ...this.state,
          groups: [
            ...this.state.groups,
            Object.assign(doc.data(), { id: doc.id }),
          ]
        });
      });
    });
  }

  render() {
    const { classes } = this.props;
    const { groups } = this.state;

    return (
      <div className={classes.root}>
        <Grid container className={classes.grid} spacing={32}>
          {
            groups.map((group, key) => (
              <Grid item key={key} md={3}>
                <Group group={group} />
              </Grid>
            ))
          }
        </Grid>
      </div>
    );
  }
}

export default  withStyles(styles)(Groups);