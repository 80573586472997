import React from 'react';
import firebase, { uiConfig } from 'firebase-config';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { Typography } from '@material-ui/core';
import Groups from 'views/Groups';

const Home = (props) => {
  const { user } = props;

  return (
    <div className="Home View">
      {
        (user) ? (
          <React.Fragment>
            <Groups />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Typography variant="title">Welcome to RollDnD</Typography>
            <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
          </React.Fragment>
        )
      }
    </div>
  );
};

export default Home;
